/**
 * Get Form Data, and return as an Object
 * @param form
 * @return object
 */
module.exports = form => {
    // Return an Iterator of current Form Entries
    const entries = (new FormData(form)).entries();

    // Map Iterator Pairings to the Form Data object
    let formData = {};
    for (const [name, value] of entries) {
        formData = {
            ...formData,
            [name]: value
        };
    }

    // Return Form Data as an Object
    return formData;
}
