import queryString from 'query-string';

/**
 * Get the URL Fragment Based on the searched key ('skill' or 'reviews')
 * @return {*[]}
 */
module.exports = key => {
    const parsedFragment = queryString.parse(location.hash);
    if (Object.keys(parsedFragment)[0] === key)
        return Object.keys(parsedFragment);
}