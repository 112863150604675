/**
 * Modify the URL Parameters
 * @param params    {object}    Object of parameters (key/value pairings) to use. This will replace any existing params in the query string.
 */
module.exports = (params = {}) => {
    if (Object.keys(params).length === 0) {
        return;
    }

    // Build Query String, which gets pushed to url
    const queryString = Object.keys(params)
        .map(param => `${param}=${params[param]}`)
        .join('&');

    // Push window.history state
    window.history.pushState(
        null,
        null,
        `?${queryString}`
    );
};
