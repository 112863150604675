/**
 * Stringify Form Data to be passed as a query string
 * @param formData      {object}        Form Data
 * @return {string}
 * @private
 */
module.exports = formData => {
    if (Object.keys(formData).length === 0) {
        return '';
    }

    const querystring = Object.keys(formData)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
        .join('&');

    return `?${querystring}`;
}
