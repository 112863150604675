/**
 * Get URL Parameters (Note: this function works in IE as well)
 * @return {*}
 */
module.exports = () => {
    // Get Query String
    const qs = window.location.search.substring(1);

    // URL Hash/Fragment Parameters
    const hashParams = window.location.hash.split('?').slice(1); // do not include the #fragment

    // URL Query Parameters
    const paramsArray = [
        ...hashParams,
        ...qs.split('&')
    ].filter(string => string !== '');

    // Collect Query Parameters into an Object
    const params = paramsArray.reduce((paramsObject, param) => {
        const [
            key,
            value
        ] = param.split('=');

        if (!key) {
            return paramsObject;
        }

        return {
            ...paramsObject,
            [decodeURIComponent(key)]: decodeURIComponent(value)
        };
    }, {});

    return params;
};
