const getQueryParameters = require('./getQueryParameters');

/**
 * Modify the URL Parameters
 * @param add       {object}    Object of parameters (key/value pairings) to add to query string
 * @param delete    {array}     Array of parameters (key) to remove from query string
 */
module.exports = ({
    add: paramsToAdd = {},
    delete: paramsToDelete = []
} = {}) => {
    // Get current query parameters
    let params = getQueryParameters();

    // Append New Params?
    Object.keys(paramsToAdd).forEach(key => {
        params = {
            ...params,
            [key]: paramsToAdd[key]
        };
    });

    // Delete Params?
    paramsToDelete.forEach(key => {
        if (params[key]) {
            delete params[key];
        }
    });

    // Build Query String, which gets pushed to url
    const queryString = Object.keys(params)
        .map(param => `${param}=${params[param]}`)
        .join('&');

    const url = (queryString !== '') ? `?${queryString}` : window.location.pathname;

    // Push window.history state
    window.history.pushState(
        null,
        null,
        url
    );
};
