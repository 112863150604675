/**
 * Initialize Tooltips
 */
module.exports = () => {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltips].map(tooltipTriggerEl => {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl, {
            animation: false,
            trigger: 'hover focus'
        })
    })
}
