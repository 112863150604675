/**
 * Translate PHP's `html_entity_decode()` to be used in Javascript
 *
 * https://github.com/kvz/locutus/blob/master/src/php/strings/html_entity_decode.js
 * https://github.com/kvz/locutus/blob/master/src/php/strings/get_html_translation_table.js
 */

const DEFINED_TABLES = [
    'HTML_ENTITIES',
    'HTML_SPECIALCHARS'
];

const DEFINED_QUOTE_STYLES = [
    'ENT_QUOTES',
    'ENT_COMPAT',
    'ENT_NOQUOTES'
];

/**
 * Get Mapped out Entities based on the provided table & quoteStyle properties.
 * @param table
 * @param quoteStyle
 */
const get_html_translation_table = ({
    table = DEFINED_TABLES[0],
    quoteStyle = DEFINED_QUOTE_STYLES[0]
} = {}) => {
    const entities = {};
    const hashMap = {};

    if (!DEFINED_TABLES.includes(table)) {
        throw new Error(`Table: ${table} not supported`);
    }

    if (!DEFINED_QUOTE_STYLES.includes(quoteStyle)) {
        throw new Error(`QuoteStyle: ${quoteStyle} not supported`);
    }

    entities['38'] = '&amp;';
    entities['60'] = '&lt;';
    entities['62'] = '&gt;';

    if (table === 'HTML_ENTITIES') {
        entities['160'] = '&nbsp;';
        entities['161'] = '&iexcl;';
        entities['162'] = '&cent;';
        entities['163'] = '&pound;';
        entities['164'] = '&curren;';
        entities['165'] = '&yen;';
        entities['166'] = '&brvbar;';
        entities['167'] = '&sect;';
        entities['168'] = '&uml;';
        entities['169'] = '&copy;';
        entities['170'] = '&ordf;';
        entities['171'] = '&laquo;';
        entities['172'] = '&not;';
        entities['173'] = '&shy;';
        entities['174'] = '&reg;';
        entities['175'] = '&macr;';
        entities['176'] = '&deg;';
        entities['177'] = '&plusmn;';
        entities['178'] = '&sup2;';
        entities['179'] = '&sup3;';
        entities['180'] = '&acute;';
        entities['181'] = '&micro;';
        entities['182'] = '&para;';
        entities['183'] = '&middot;';
        entities['184'] = '&cedil;';
        entities['185'] = '&sup1;';
        entities['186'] = '&ordm;';
        entities['187'] = '&raquo;';
        entities['188'] = '&frac14;';
        entities['189'] = '&frac12;';
        entities['190'] = '&frac34;';
        entities['191'] = '&iquest;';
        entities['192'] = '&Agrave;';
        entities['193'] = '&Aacute;';
        entities['194'] = '&Acirc;';
        entities['195'] = '&Atilde;';
        entities['196'] = '&Auml;';
        entities['197'] = '&Aring;';
        entities['198'] = '&AElig;';
        entities['199'] = '&Ccedil;';
        entities['200'] = '&Egrave;';
        entities['201'] = '&Eacute;';
        entities['202'] = '&Ecirc;';
        entities['203'] = '&Euml;';
        entities['204'] = '&Igrave;';
        entities['205'] = '&Iacute;';
        entities['206'] = '&Icirc;';
        entities['207'] = '&Iuml;';
        entities['208'] = '&ETH;';
        entities['209'] = '&Ntilde;';
        entities['210'] = '&Ograve;';
        entities['211'] = '&Oacute;';
        entities['212'] = '&Ocirc;';
        entities['213'] = '&Otilde;';
        entities['214'] = '&Ouml;';
        entities['215'] = '&times;';
        entities['216'] = '&Oslash;';
        entities['217'] = '&Ugrave;';
        entities['218'] = '&Uacute;';
        entities['219'] = '&Ucirc;';
        entities['220'] = '&Uuml;';
        entities['221'] = '&Yacute;';
        entities['222'] = '&THORN;';
        entities['223'] = '&szlig;';
        entities['224'] = '&agrave;';
        entities['225'] = '&aacute;';
        entities['226'] = '&acirc;';
        entities['227'] = '&atilde;';
        entities['228'] = '&auml;';
        entities['229'] = '&aring;';
        entities['230'] = '&aelig;';
        entities['231'] = '&ccedil;';
        entities['232'] = '&egrave;';
        entities['233'] = '&eacute;';
        entities['234'] = '&ecirc;';
        entities['235'] = '&euml;';
        entities['236'] = '&igrave;';
        entities['237'] = '&iacute;';
        entities['238'] = '&icirc;';
        entities['239'] = '&iuml;';
        entities['240'] = '&eth;';
        entities['241'] = '&ntilde;';
        entities['242'] = '&ograve;';
        entities['243'] = '&oacute;';
        entities['244'] = '&ocirc;';
        entities['245'] = '&otilde;';
        entities['246'] = '&ouml;';
        entities['247'] = '&divide;';
        entities['248'] = '&oslash;';
        entities['249'] = '&ugrave;';
        entities['250'] = '&uacute;';
        entities['251'] = '&ucirc;';
        entities['252'] = '&uuml;';
        entities['253'] = '&yacute;';
        entities['254'] = '&thorn;';
        entities['255'] = '&yuml;';
    }

    if (quoteStyle !== 'ENT_NOQUOTES') {
        entities['34'] = '&quot;';
    }

    if (quoteStyle === 'ENT_QUOTES') {
        entities['39'] = '&#39;';
    }

    // ascii decimals to real symbols
    for (const entity in entities) {
        if (entities.hasOwnProperty(entity)) {
            hashMap[String.fromCharCode(entity)] = entities[entity];
        }
    }

    return hashMap;
}

/**
 * Clean String Entities before any remapping
 * @param string
 */
const clean_string_entities = string => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = string.toString();
    return textarea.value;
}

/**
 * Decode HTML Entities by providing a String
 * @param string        {string}        Value to decode
 * @param table         {string}        HTML Decode Configuration
 * @param quoteStyle    {string}        HTML Decode Configuration
 * @return              {string}        Returns the decoded string
 */
const html_entity_decode = (string, {
    table = DEFINED_TABLES[0],
    quoteStyle = DEFINED_QUOTE_STYLES[0]
} = {}) => {
    let decodedString = clean_string_entities(string);

    const hashMap = get_html_translation_table({ table, quoteStyle });
    if (hashMap === false) {
        return false;
    }

    // @todo: &amp; problem
    // https://locutus.io/php/get_html_translation_table:416#comment_97660
    delete (hashMap['&']);
    hashMap['&'] = '&amp;';

    for (const symbol in hashMap) {
        const entity = hashMap[symbol];
        decodedString = decodedString.split(entity).join(symbol);
    }
    decodedString = decodedString.split('&#039;').join("'");

    return decodedString;
}

module.exports = html_entity_decode;
