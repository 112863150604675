const stripTags = require('../Sanitizer/lib/strip_tags');
const getFormData = require('./getFormData');
const getQueryParameters = require('./getQueryParameters');
const replaceQueryParameters = require('./replaceQueryParameters');
const stringifyFormData = require('./stringifyFormData');
const updateQueryParameters = require('./updateQueryParameters');
const getURLFragment = require('../../sharable/Utilities/getURLFragment');
import initializeTooltips from '@js/sharable/bootstrapHandlers/tooltips';

/**
 * Inserts HTML line breaks
 * @param str
 * @return {string}
 */
const nl2br = str => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
};

/**
 * Converts Escaped Quotes to Unescaped Double Quotes
 * @param str
 * @return {string}
 */
const unescapeQuotes = str => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/\\"/g,'\"');
};

/**
 * Truncate long strings
 * @param str   to truncate
 * @param n     truncate at specified length
 * @returns string
 */
const truncate = (str, n) => {
    return (str.length > n) ? str.slice(0, n-1) + '&hellip;' : str;
};

/**
 * Custom Debounce Handler
 * @param callback
 * @param wait
 * @returns {(function(...[*]): void)|*}
 */
const debounce = (callback, wait) => {
    let timeoutId = null;

    return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
};

/**
 * Utility Function: Insert HTMLElement after reference Node.
 * @param el
 * @param referenceNode
 */
const insertAfter = (el, referenceNode) => {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

/**
 * Utility Function: Value is Empty.
 * @param value
 * @returns {boolean}
 */
const isEmpty = (value) => {
    return (
        value === ''
        || value === null
        || value === undefined
        || value == 0
        || (typeof value === 'object' && Array.isArray(value) && value.length === 0)
        || (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
    );
};

/**
 * Utility Function: Converts double quotes to encoded html entity
 * @param str
 * @returns {string}
 */
const encodeQuotes = str => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/\"/g, '&quot;');
};

/**
 * Utility Function: Check if JSON is able to be parsed
 * @param str
 * @returns {boolean}
 */
const isValidJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 * Utility Function: Sort Array Items
 * @param a
 * @param b
 * @param order
 * @return {number}
 */
const sort = (a, b, { order = 'asc' } = {}) => {
    if (order.toLowerCase() === 'asc') {
        if (a > b) {
            return 1;
        }
        else if (b > a) {
            return -1;
        }
        else {
            return 0;
        }
    }
    else if (order.toLowerCase() === 'desc') {
        if (b > a) {
            return 1;
        }
        else if (a > b) {
            return -1;
        }
        else {
            return 0;
        }
    }
    else {
        return 0;
    }
};

/**
 * Utility Function: Replaces spaces & ampersands with underscores
 * @param str
 * @return {*}
 */
const slugify = (str) => str.toLowerCase().replace(/[\s&]+/g, '_');

/**
 * Scroll To Element
 * @param element
 * @private
 */
const scrollToPageElement = (element) => {
    element.scrollIntoView({ behavior: 'smooth' });
};

/**
 * Strip Square Brackets
 * @param value
 * @returns {*}
 */
const stripSquareBrackets = (value) => {
    const regex = new RegExp(/\[(?:\d)?\]/, 'gi');
    return value.replace(regex, '');
};

/**
 * Format number to have dollar sign
 * @param value
 * @returns {string}
 */
const moneyFormat = (value) => {
    const isPriceRange = (value.indexOf('-') > 0);
    if (isPriceRange) {
        const priceRangeSegments = value.split('-');
        const min = priceRangeSegments[0] ? priceRangeSegments[0].trim() : value;
        const max = priceRangeSegments[1] ? priceRangeSegments[1].trim() : null;
        if (max !== null) {
            return `$${min} - $${max}`;
        }
        else {
            return `$${min}`;
        }
    }
    else {
        return `$${value}`;
    }
};

const isAudioFile = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase();
    return ['wav', 'mp3', 'ogg', 'm4a'].includes(ext);
}

const isVideoFile = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase();
    return ['mp4', 'mov'].includes(ext);
};

const isAudioOrVideoFile = (fileName) => {
    return isAudioFile(fileName) || isVideoFile(fileName);
}

const convertToBoolean = (value) => {
    switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
            return true;
        default:
            return false;
    }
}
window.convertToBoolean = convertToBoolean;

module.exports = {
    getFormData,
    getQueryParameters,
    replaceQueryParameters,
    stringifyFormData,
    updateQueryParameters,
    nl2br,
    unescapeQuotes,
    truncate,
    stripTags,
    debounce,
    insertAfter,
    isEmpty,
    encodeQuotes,
    isValidJSON,
    sort,
    slugify,
    scrollToPageElement,
    stripSquareBrackets,
    moneyFormat,
    getURLFragment,
    isAudioFile,
    isVideoFile,
    isAudioOrVideoFile,
    convertToBoolean,
    initializeTooltips
};
